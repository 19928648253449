import React, { useState } from "react";
import { useLockedBody } from "usehooks-ts";
import { AnimatePresence, motion } from "framer-motion";

import { useKeyPress } from "@jasperlabs/hooks";
import * as Portal from "@radix-ui/react-portal";
import { MobileNotificationsLink } from "components/layout/AppHeader/NotificationsLink";
import DefaultNavigation from "./DefaultNavigation";
import { MenuToggle } from "./MenuToggle";

const MobileNavigation = ({
	hasOffers,
	offersCount,
	sellOrdersCount,
}: {
	hasOffers: boolean;
	offersCount: number;
	sellOrdersCount: number;
}) => {
	const [mobileNavigationIsActive, setMobileNavigationIsActive] =
		useState(false);

	useKeyPress({
		onKeyPress: () => setMobileNavigationIsActive(false),
		targetKey: "Escape",
	});

	const toggleNav = () => setMobileNavigationIsActive((prev) => !prev);

	useLockedBody(mobileNavigationIsActive, "root");

	return (
		<nav role="navigation">
			<div className="flex gap-1">
				<AnimatePresence initial={false}>
					{mobileNavigationIsActive === false && (
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							exit={{ opacity: 0 }}
							key="notification bell"
							transition={{ ease: "easeIn", duration: 0.2 }}
						>
							<MobileNotificationsLink />
						</motion.div>
					)}
				</AnimatePresence>
				<MenuToggle
					isOpen={mobileNavigationIsActive}
					onClick={toggleNav}
					height={18}
					width={18}
					data-testid="mobile-nav-toggle"
					aria-controls="primary-mobile-nav"
					aria-expanded={mobileNavigationIsActive}
					aria-label={
						mobileNavigationIsActive ? "Close navigation" : "Open navigation"
					}
				/>
			</div>
			<Portal.Root>
				<AnimatePresence>
					{mobileNavigationIsActive && (
						<DefaultNavigation
							onClose={() => setMobileNavigationIsActive(false)}
							key="motionNav"
							hasOffers={hasOffers}
							offersCount={offersCount}
							sellOrdersCount={sellOrdersCount}
						/>
					)}
				</AnimatePresence>
			</Portal.Root>
		</nav>
	);
};

export default MobileNavigation;
