import React, { useState } from "react";
import RouterLink from "next/link";
import { gql } from "@apollo/client";

import * as DropdownMenu from "@radix-ui/react-dropdown-menu";

import {
	useValueAtBreakpoint,
	Breakpoint,
	Link,
	LinkVariant,
	IconCogStroke,
	IconSwitch,
} from "@jasperlabs/jux-next";

import clsx from "clsx";

import AddInvestingEntityButton from "./AddInvestingEntityButton";

import InvestingEntityLabel from "./InvestingEntityLabel";

import { useInvestingEntitiesDropdownQueryQuery } from "./InvestingEntitiesDropdownMenu.generated";

const LINK_STYLES =
	"bg-white py-2 px-5 transition-colors duration-200 hover:bg-neutral-50  flex w-full focus:no-underline";

const InvestingEntitiesDropdownMenu = () => {
	const [isOpen, setIsOpen] = useState(false);

	const { data, loading } = useInvestingEntitiesDropdownQueryQuery();

	const menuButtonAriaLabel = isOpen
		? "Close investing entities menu"
		: "Open investing entities menu";

	const sideOffset = useValueAtBreakpoint({
		[Breakpoint.XS]: 15,
		[Breakpoint.LG]: 12,
	});

	if (loading) {
		return null;
	}

	const investingEntity = data?.account?.activeInvestingEntity;

	if (!investingEntity) {
		return <AddInvestingEntityButton />;
	}

	return (
		<DropdownMenu.Root onOpenChange={(open) => setIsOpen(open)}>
			<DropdownMenu.Trigger asChild>
				<InvestingEntityLabel.Button
					investingEntity={investingEntity}
					aria-label={menuButtonAriaLabel}
				/>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content
					className="shadow-card-sm oveflow-hidden z-[9000] w-[98vw] divide-y divide-neutral-200 overflow-hidden rounded-md bg-white md:w-[280px]"
					sideOffset={sideOffset}
					align="end"
					alignOffset={0}
					data-testid="investing-entities-dropdown-menu"
					aria-label="Investing Entities Menu"
				>
					<InvestingEntityLabel investingEntity={investingEntity} />
					<section>
						<DropdownMenu.DropdownMenuItem asChild>
							<Link
								icon={<IconSwitch className="text-[20px] text-black" />}
								variant={LinkVariant.Black}
								className={clsx("text-body-default", LINK_STYLES)}
								// Note this differs from the ticket but think it will avoid a bunch of work refactoring the /investing-entities sub-route pattern
								href="/switch-investing-entities"
								aria-label="Switch or add investing entity"
								as={RouterLink}
							>
								Switch / Add investing entity
							</Link>
						</DropdownMenu.DropdownMenuItem>
						<DropdownMenu.DropdownMenuItem asChild>
							<Link
								icon={<IconCogStroke className="text-[18px] text-black" />}
								variant={LinkVariant.Black}
								className={clsx("text-body-default", LINK_STYLES)}
								href="/investing-entities/details"
								aria-label="Investing entity settings"
								as={RouterLink}
							>
								Investing entity settings
							</Link>
						</DropdownMenu.DropdownMenuItem>
					</section>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

gql`
	query InvestingEntitiesDropdownQuery {
		account {
			id
			activeInvestingEntity {
				...InvestingEntityLabel
			}
			investingEntities {
				id
			}
		}
	}
	${InvestingEntityLabel.fragment}
`;

export default InvestingEntitiesDropdownMenu;
