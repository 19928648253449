import React from "react";
import RouterLink from "next/link";

import { TenantLogo as Logo } from "tenants/components/TenantLogo";

export const TenantLogo = (props) => (
	<Logo className="w-[96px] text-inherit md:w-[118px]" {...props} />
);

export const TenantLogoLink = () => (
	<RouterLink aria-label="Home" href="/" className="text-white">
		<Logo />
	</RouterLink>
);
