"use client";

import React from "react";
import { Link, LinkProps } from "@jasperlabs/jux-next";

import { useActiveTenant } from "tenants/TenantProvider";

function TenantSupportLink({
	children = "Contact",
	...props
}: Omit<LinkProps<"a">, "href" | "children"> & {
	children?: React.ReactNode;
}) {
	const tenant = useActiveTenant();

	const { supportUrl } = tenant.urls;

	return (
		<Link href={supportUrl ?? ""} target="_blank" {...props}>
			{children}
		</Link>
	);
}

export default TenantSupportLink;
