import * as React from "react";

const SvgBell = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 34 41" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M10.753 31.22h12.494l.227.91c.142.565.213 1.134.213 1.708 0 3.672-3.015 6.687-6.687 6.687s-6.688-3.015-6.688-6.687c0-.639.074-1.15.213-1.708l.228-.91zm1.966 2.403c-.003.069-.004.14-.004.215 0 2.345 1.94 4.285 4.285 4.285s4.285-1.94 4.285-4.285c0-.072-.002-.143-.005-.215h-8.56z"
			fill="currentColor"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.065 3.13C13.497 1.336 15.163 0 17.042 0c1.88 0 3.488 1.328 3.926 3.086 2.013.585 4.173 1.664 5.91 3.468 1.997 2.073 3.36 5.03 3.36 9.067 0 8.706 2.823 12.179 3.03 12.434.015.018.017.02.004.007l.038.039.035.042c.361.433.576.943.646 1.497l.009.075v3.909H0v-3.91l.01-.074c.07-.564.297-1.115.76-1.578l.034-.034.038-.046a5.44 5.44 0 00.317-.444c.276-.425.662-1.11 1.053-2.09.78-1.96 1.591-5.121 1.591-9.786 0-4.055 1.363-7.013 3.363-9.083 1.735-1.797 3.89-2.866 5.899-3.45zm3.977-.727c-.906 0-1.683.78-1.683 1.64v.996l-.93.216c-1.91.444-3.968 1.37-5.535 2.993-1.543 1.597-2.688 3.947-2.688 7.414 0 4.95-.861 8.413-1.762 10.675-.45 1.129-.909 1.954-1.271 2.511-.18.278-.339.491-.462.643a3.807 3.807 0 01-.251.28l-.01.01a.188.188 0 00-.029.044.286.286 0 00-.018.059v1.337h29.194v-1.338a.444.444 0 00-.087-.19c-.549-.614-3.674-4.648-3.674-14.072 0-3.446-1.144-5.796-2.69-7.4-1.568-1.63-3.626-2.564-5.534-3.008l-.93-.216v-.954c0-.883-.757-1.64-1.64-1.64z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgBell;
