/* eslint-disable */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
import { InvestingEntityLabelFragmentDoc } from './InvestingEntityLabel.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type InvestingEntitiesDropdownQueryQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type InvestingEntitiesDropdownQueryQuery = { __typename: 'Query', account?: { __typename: 'Account', id: string, activeInvestingEntity?: { __typename: 'InvestingEntity', id: string, name?: string | null, entityTypeEnum: Types.InvestingEntityType } | null, investingEntities?: Array<{ __typename: 'InvestingEntity', id: string }> | null } | null };


export const InvestingEntitiesDropdownQueryDocument = gql`
    query InvestingEntitiesDropdownQuery {
  account {
    id
    activeInvestingEntity {
      ...InvestingEntityLabel
    }
    investingEntities {
      id
    }
  }
}
    ${InvestingEntityLabelFragmentDoc}`;

/**
 * __useInvestingEntitiesDropdownQueryQuery__
 *
 * To run a query within a React component, call `useInvestingEntitiesDropdownQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvestingEntitiesDropdownQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvestingEntitiesDropdownQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useInvestingEntitiesDropdownQueryQuery(baseOptions?: Apollo.QueryHookOptions<InvestingEntitiesDropdownQueryQuery, InvestingEntitiesDropdownQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvestingEntitiesDropdownQueryQuery, InvestingEntitiesDropdownQueryQueryVariables>(InvestingEntitiesDropdownQueryDocument, options);
      }
export function useInvestingEntitiesDropdownQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvestingEntitiesDropdownQueryQuery, InvestingEntitiesDropdownQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvestingEntitiesDropdownQueryQuery, InvestingEntitiesDropdownQueryQueryVariables>(InvestingEntitiesDropdownQueryDocument, options);
        }
export type InvestingEntitiesDropdownQueryQueryHookResult = ReturnType<typeof useInvestingEntitiesDropdownQueryQuery>;
export type InvestingEntitiesDropdownQueryLazyQueryHookResult = ReturnType<typeof useInvestingEntitiesDropdownQueryLazyQuery>;
export type InvestingEntitiesDropdownQueryQueryResult = Apollo.QueryResult<InvestingEntitiesDropdownQueryQuery, InvestingEntitiesDropdownQueryQueryVariables>;