import * as R from "ramda";

export const formatSplitChunks = (str, chunkFormat) => {
	let currentPosition = 0;
	return chunkFormat.reduce((acc, count) => {
		const chunkSlice = `${str}`.slice(currentPosition, currentPosition + count);
		currentPosition += count;
		return [...acc, chunkSlice];
	}, []);
};

export const capitalize = R.compose(
	R.converge(R.concat(), [R.compose(R.toUpper, R.head), R.tail]),
	R.when(R.isNil, () => ""),
);

export const toTitleCase = R.compose(
	R.join(" "),
	R.map(capitalize),
	R.split(" "),
	R.toLower,
	R.defaultTo(""),
);

export const concatAddress = R.compose(
	R.join(""),
	R.intersperse(", "),
	R.filter(Boolean),
	R.props(["addressLine1", "addressLine2", "suburb", "city", "postCode"]),
	R.defaultTo({}),
);

export const concatLocation = R.compose(
	R.join(""),
	R.intersperse(", "),
	R.filter(Boolean),
	R.paths([["city"], ["country", "name"]]),
	R.defaultTo({}),
);

export const formatFullName = R.compose(
	R.when(R.isEmpty, () => "-"),
	R.join(""),
	R.intersperse(" "),
	R.filter(Boolean),
	R.props(["firstName", "middleName", "lastName"]),
	R.defaultTo({}),
);

export const formatDisplayName = R.compose(
	R.when(R.not, () => "-"),
	R.either(R.prop("preferredName"), R.prop("firstName")),
	R.defaultTo({}),
);
