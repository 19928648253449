import React from "react";
import clsx from "clsx";

import {
	useNotificationsQuery,
	filterNotificationsByIncomplete,
} from "../../../utilities/notifications";

const NotificationsCountPill = ({ className, ...props }) => {
	const [notifications] = useNotificationsQuery();

	const incompleteNotificationsCount =
		filterNotificationsByIncomplete(notifications).length;

	if (incompleteNotificationsCount === 0) {
		return null;
	}

	return (
		<div
			aria-label={`${incompleteNotificationsCount} notifications`}
			value={incompleteNotificationsCount}
			className={clsx(
				"bg-notification-pill text-notification-pill text-label-sm flex h-[20px] w-[20px] items-center justify-center rounded-full p-2 lg:h-[22px] lg:w-[22px]",
				className,
			)}
			{...props}
		>
			{incompleteNotificationsCount}
		</div>
	);
};

export default NotificationsCountPill;
