import { useCallback, useEffect, useState, useRef } from "react";
import hasWindow from "../utils/hasWindow";

interface UseKeyPressOptions {
	targetKey: KeyboardEvent["key"];
	onKeyPress?: () => void;
}

export default function useKeyPress({
	targetKey,
	onKeyPress,
}: UseKeyPressOptions): boolean {
	const [keyPressed, setKeyPressed] = useState(false);
	const hasCalled = useRef(false);

	const downHandler = useCallback(
		({ key }: { key: KeyboardEvent["key"] }) => {
			if (key === targetKey) {
				hasCalled.current = false;
				setKeyPressed(true);
			}
		},
		[targetKey],
	);

	const upHandler = useCallback(
		({ key }: { key: KeyboardEvent["key"] }) => {
			if (key === targetKey) {
				setKeyPressed(false);
			}
		},
		[targetKey],
	);

	useEffect(() => {
		if (keyPressed && !hasCalled.current) {
			hasCalled.current = true;
			onKeyPress && onKeyPress();
		}
	}, [onKeyPress, keyPressed]);

	useEffect(() => {
		if (!hasWindow()) {
			return;
		}

		window.addEventListener("keydown", downHandler);
		window.addEventListener("keyup", upHandler);

		// eslint-disable-next-line consistent-return
		return () => {
			window.removeEventListener("keydown", downHandler);
			window.removeEventListener("keyup", upHandler);
		};
	}, [downHandler, upHandler]);

	return keyPressed;
}
