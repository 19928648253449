import React from "react";
import { Link, LinkProps } from "@jasperlabs/jux-next";

import { useActiveTenant } from "tenants/TenantProvider";

export function TenantPrivacyPolicyLink({
	children = "Privacy Policy",
	...props
}: Omit<LinkProps<"a">, "href" | "children"> & { children?: React.ReactNode }) {
	const tenant = useActiveTenant();

	const { privacyPolicyUrl } = tenant.urls;

	if (!privacyPolicyUrl) {
		return null;
	}

	return (
		<Link href={privacyPolicyUrl} target="_blank" {...props}>
			{children}
		</Link>
	);
}
