import React from "react";

const Switch = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="16"
		height="16"
		viewBox="0 0 16 16"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M7.26855 3.75H9.96525C11.0698 3.75 11.9653 4.64543 11.9653 5.75V12.2495"
			stroke="#1E2332"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M8.73145 12.25L6.03475 12.25C4.93018 12.25 4.03475 11.3546 4.03475 10.25L4.03475 3.75053"
			stroke="#1E2332"
			strokeWidth="1.5"
			strokeLinecap="round"
		/>
		<path
			d="M1.65238 6.13285L4.03516 3.75008L6.41793 6.13285"
			stroke="#1E2332"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.3476 9.86715L11.9648 12.2499L9.58207 9.86715"
			stroke="#1E2332"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Switch;
