/* eslint-disable */
import * as Types from '../../../../types';

import { gql } from '@apollo/client';
export type InvestingEntityLabelFragment = { __typename: 'InvestingEntity', id: string, name?: string | null, entityTypeEnum: Types.InvestingEntityType };

export const InvestingEntityLabelFragmentDoc = gql`
    fragment InvestingEntityLabel on InvestingEntity {
  id
  name
  entityTypeEnum
}
    `;