import React from "react";

const SvgPlus = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M17.168 17.167V3h5.667v14.167H37v5.666H22.835V37h-5.667V22.833H3v-5.666h14.168z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgPlus;
