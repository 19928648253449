import { Button, ButtonVariant, ButtonSize } from "@jasperlabs/jux-next";
import React from "react";
import RouterLink from "next/link";

type Props = {
	variant?: ButtonVariant;
	className?: string;
};
const AddInvestingEntityButton = ({
	variant = ButtonVariant.InvertedSecondary,
	className,
}: Props) => (
	<Button
		as={RouterLink}
		variant={variant}
		size={ButtonSize.Small}
		data-testid="add-investing-entity-button"
		href="/add-investing-entity"
		className={className}
	>
		Add investing entity
	</Button>
);

export default AddInvestingEntityButton;
