import React from "react";
import { motion } from "framer-motion";
import clsx from "clsx";

const top = {
	closed: {
		rotate: 0,
		translateY: 0,
	},
	opened: {
		rotate: 45,
		translateY: 2,
	},
};

const middle = {
	closed: {
		opacity: 1,
		scale: 1,
	},
	opened: {
		opacity: 0,
		scale: 0,
	},
};
const bottom = {
	closed: {
		rotate: 0,
		translateY: 0,
	},
	opened: {
		rotate: -45,
		translateY: -2,
	},
};

export type MenuToggleProps = {
	isOpen: boolean;
	onClick: () => void;
	width?: number;
	height?: number;
};

export const MenuToggle = ({
	isOpen,
	onClick,
	width = 16,
	height = 12,
	...rest
}: MenuToggleProps) => {
	const variant = isOpen ? "opened" : "closed";
	const unitHeight = 4;
	const unitWidth = (unitHeight * width) / height;
	return (
		<button
			type="button"
			onClick={onClick}
			className={clsx(
				"text-link-nav flex cursor-pointer stroke-current",
				"hover:bg-button-inverted-nav-hover/20 flex h-[40px] items-center rounded-md bg-transparent py-2 px-3 text-[21px]",
			)}
			{...rest}
		>
			<svg
				viewBox={`0 0 ${unitWidth} ${unitHeight}`}
				overflow="visible"
				preserveAspectRatio="none"
				width={width}
				height={height}
				strokeWidth="2.5"
			>
				<motion.line
					x1="0"
					x2={unitWidth}
					y1="0"
					y2="0"
					variants={top}
					animate={variant}
					initial="closed"
					vectorEffect="non-scaling-stroke"
				/>
				<motion.line
					x1="0"
					x2={unitWidth}
					y1="2"
					y2="2"
					variants={middle}
					animate={variant}
					initial="closed"
					vectorEffect="non-scaling-stroke"
				/>
				<motion.line
					x1="0"
					x2={unitWidth}
					y1="4"
					y2="4"
					variants={bottom}
					animate={variant}
					initial="closed"
					vectorEffect="non-scaling-stroke"
				/>
			</svg>
		</button>
	);
};
