import { TagVariant } from "@jasperlabs/jux-next";
import {
	InvestingEntityType,
	InvestingEntityAvailableFunds,
	NzPrescribedInvestorRateBasisPoints,
	InvestingEntityFrequencyOfInvestment,
	InvestingEntityReasonForInvesting,
	SourceOfFundsSource,
	InvestingEntityTrustType,
	InvestingEntityCompanyType,
	InvestingEntityPartnershipType,
	InvestingEntityAccountAccess,
	ResidentWithholdingTaxRateBasisPoints,
	DocumentCategory,
} from "types";

export const InvestingEntityTypeLabels: Record<InvestingEntityType, string> = {
	[InvestingEntityType.Company]: "Company",
	[InvestingEntityType.Trust]: "Trust",
	[InvestingEntityType.JointIndividual]: "Joint Individual",
	[InvestingEntityType.Individual]: "Individual",
	[InvestingEntityType.Partnership]: "Partnership",
};

export const InvestingEntityTagVariants = {
	[InvestingEntityType.Trust]: TagVariant.MutedSuccess,
	[InvestingEntityType.Company]: TagVariant.MutedViolet,
	[InvestingEntityType.Individual]: TagVariant.MutedBlue,
	[InvestingEntityType.Partnership]: TagVariant.MutedYellow,
	[InvestingEntityType.JointIndividual]: TagVariant.MutedYellow,
};

export const investingEntityAvailableFundsLabels: Record<
	InvestingEntityAvailableFunds,
	string
> = {
	[InvestingEntityAvailableFunds.Under_50K]: "Under $50K",
	[InvestingEntityAvailableFunds.Between_50KAnd_250K]: "Between $50K and $250K",
	[InvestingEntityAvailableFunds.Between_250KAnd_1M]: "Between $250K and $1M",
	[InvestingEntityAvailableFunds.Over_1M]: "Over $1M",
};

export const nzPrescribedInvestorRateBasisPointsLabels: Record<
	NzPrescribedInvestorRateBasisPoints,
	string
> = {
	[NzPrescribedInvestorRateBasisPoints.Unset]: "None",
	[NzPrescribedInvestorRateBasisPoints.Rate_0]: "0%",
	[NzPrescribedInvestorRateBasisPoints.Rate_1050]: "10.5%",
	[NzPrescribedInvestorRateBasisPoints.Rate_1750]: "17.5%",
	[NzPrescribedInvestorRateBasisPoints.Rate_2800]: "28%",
};

export const investingEntityFrequencyOfInvestmentLabels: Record<
	InvestingEntityFrequencyOfInvestment,
	string
> = {
	[InvestingEntityFrequencyOfInvestment.OneOff]: "One off",
	[InvestingEntityFrequencyOfInvestment.MultipleOffers]: "Ongoing investment",
};

export const deprecatedSourceOfFundsLabel: Record<
	Exclude<
		SourceOfFundsSource,
		// InvestmentIncome & OtherIncome are behind the `EXTEND_NATURE_AND_PURPOSE_ANSWERS` feature flag
		| SourceOfFundsSource.InvestmentIncome
		| SourceOfFundsSource.OtherIncome
		| SourceOfFundsSource.Superannuation
	>,
	string
> = {
	[SourceOfFundsSource.BusinessIncome]: "Business income",
	[SourceOfFundsSource.Gifts]: "Gifts",
	[SourceOfFundsSource.Inheritances]: "Inheritance",
	[SourceOfFundsSource.LoanDrawdown]: "Loan drawdown",
	[SourceOfFundsSource.RentalIncome]: "Rental income",
	[SourceOfFundsSource.SalaryAndWages]: "Salary and wages",
	[SourceOfFundsSource.SaleOfProperty]: "Sale of asset",
	[SourceOfFundsSource.SaleOfShares]: "Sale of shares",
} as const;

export const sourceOfFundsSourceLabels: Record<SourceOfFundsSource, string> = {
	[SourceOfFundsSource.BusinessIncome]: "Business income",
	[SourceOfFundsSource.Gifts]: "Gifts",
	[SourceOfFundsSource.Inheritances]: "Inheritance",
	[SourceOfFundsSource.LoanDrawdown]: "Loan drawdown",
	[SourceOfFundsSource.RentalIncome]: "Rental income",
	[SourceOfFundsSource.SalaryAndWages]: "Salary and wages",
	[SourceOfFundsSource.SaleOfProperty]: "Sale of asset",
	[SourceOfFundsSource.SaleOfShares]: "Sale of shares",
	[SourceOfFundsSource.InvestmentIncome]: "Investment income",
	[SourceOfFundsSource.Superannuation]: "Superannuation",
	[SourceOfFundsSource.OtherIncome]: "Other income",
};

export const investingEntityReasonForInvestingLabels: Record<
	InvestingEntityReasonForInvesting,
	string
> = {
	[InvestingEntityReasonForInvesting.CapitalGrowth]: "Capital growth",
	[InvestingEntityReasonForInvesting.CapitalPreservation]:
		"Capital preservation",
	[InvestingEntityReasonForInvesting.EstatePlanning]: "Estate planning",
	[InvestingEntityReasonForInvesting.OngoingIncome]: "Ongoing income",
};

export const investingEntityTrustTypeLabels: Record<
	InvestingEntityTrustType,
	string
> = {
	[InvestingEntityTrustType.Estate]: "Estate",
	[InvestingEntityTrustType.FamilyTrust]: "Family Trust",
	[InvestingEntityTrustType.TradingTrust]: "Trading Trust",
	[InvestingEntityTrustType.TestamentaryTrust]: "Testamentary Trust",
	[InvestingEntityTrustType.SuperFundTrust]: "Super Fund Trust",
	[InvestingEntityTrustType.SelfManagedSuperFund]: "Self-Managed Super Fund",
	[InvestingEntityTrustType.CharitableTrust]: "Charitable Trust",
	[InvestingEntityTrustType.RegisteredManagedInvestmentScheme]:
		"Registered Managed Investment Scheme",
	[InvestingEntityTrustType.GovernmentSuperannuationFund]:
		"Government Superannuation Fund",
	[InvestingEntityTrustType.Discretionary]: "Discretionary",
	[InvestingEntityTrustType.NonDiscretionary]: "Non Discretionary",
	[InvestingEntityTrustType.UnitTrust]: "Unit Trust",
	[InvestingEntityTrustType.Other]: "Other",
};

export const investingEntityCompanyTypeLabels: Record<
	InvestingEntityCompanyType,
	string
> = {
	[InvestingEntityCompanyType.LimitedCompany]: "Limited company",
	[InvestingEntityCompanyType.NonProfit]: "Non-profit",
	[InvestingEntityCompanyType.SoleTrader]: "Sole trader",
};

export const investingEntityPartnershipTypeLabels: Record<
	InvestingEntityPartnershipType,
	string
> = {
	[InvestingEntityPartnershipType.LimitedPartnership]: "Limited partnership",
	[InvestingEntityPartnershipType.GeneralPartnership]: "General partnership",
};

export const investingEntityAccountAccessLabels: Record<
	InvestingEntityAccountAccess,
	string
> = {
	[InvestingEntityAccountAccess.Full]: "Full access",
};

export const residentWithholdingTaxRateBasisPointsLabels: Record<
	ResidentWithholdingTaxRateBasisPoints,
	string
> = {
	[ResidentWithholdingTaxRateBasisPoints.Rate_0]: "0%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_1050]: "10.5%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_1750]: "17.5%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_1900]: "19%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_2800]: "28%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_3000]: "30%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_3250]: "32.5%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_3300]: "33%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_3700]: "37%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_3900]: "39%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_4500]: "45%",
};

export const deprecatedResidentWithholdingTaxRateBasisPointsLabels: Record<
	Exclude<
		ResidentWithholdingTaxRateBasisPoints,
		ResidentWithholdingTaxRateBasisPoints.Rate_2800
	>,
	string
> = {
	[ResidentWithholdingTaxRateBasisPoints.Rate_0]: "0%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_1050]: "10.5%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_1750]: "17.5%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_1900]: "19%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_3000]: "30%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_3250]: "32.5%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_3300]: "33%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_3700]: "37%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_3900]: "39%",
	[ResidentWithholdingTaxRateBasisPoints.Rate_4500]: "45%",
};

export const documentCategoryLabels: Record<DocumentCategory, string> = {
	[DocumentCategory.DistributionStatements]: "Distribution Statements",
	[DocumentCategory.Risk]: "Risk",
	[DocumentCategory.SourceOfFunds]: "Source of funds",
	[DocumentCategory.SourceOfWealth]: "Source of wealth",
	[DocumentCategory.OfferDocuments]: "Offer Documents",
	[DocumentCategory.FinancialReports]: "Financial Reports",
	[DocumentCategory.PerformanceReports]: "Performance Reports",
	[DocumentCategory.SubscriptionDocuments]: "Subscription Documents",
	[DocumentCategory.TaxStatements]: "Tax Statements",
	[DocumentCategory.TaxResidency]: "Tax Residency",
	[DocumentCategory.Other]: "Other",
};
