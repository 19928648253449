import React from "react";
import RouterLink from "next/link";

import { IconBell } from "@jasperlabs/jux-next";

import NotificationsCountPill from "./NotificationsCountPill";

const MobileNotificationsLink = () => (
	<RouterLink
		href="/notifications"
		aria-label="Notifications"
		className="text-link-nav hover:bg-button-inverted-nav-hover/20 flex h-[40px] items-center rounded-md bg-transparent py-2 lg:h-[48px]"
	>
		<div className="relative px-3">
			<div className="text-[25px] lg:text-[28px] ">
				<IconBell />
			</div>
			<NotificationsCountPill className="absolute -top-1 right-1" />
		</div>
	</RouterLink>
);

export default MobileNotificationsLink;
