import * as React from "react";

const SvgAccount = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width="1em"
		height="1em"
		viewBox="0 0 13 14"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.23346 6.91103C10.0339 6.20663 10.5391 5.17451 10.5391 4.02441C10.5391 1.90157 8.81816 0.180664 6.69531 0.180664C4.57247 0.180664 2.85156 1.90157 2.85156 4.02441C2.85156 5.17451 3.35668 6.20663 4.15716 6.91103C2.00564 7.87999 0.507812 10.0429 0.507812 12.5557V13.8193H12.8828V12.5557C12.8828 10.0429 11.385 7.87999 9.23346 6.91103ZM4.35156 4.02441C4.35156 2.73 5.4009 1.68066 6.69531 1.68066C7.98973 1.68066 9.03906 2.73 9.03906 4.02441C9.03906 5.31883 7.98973 6.36816 6.69531 6.36816C5.4009 6.36816 4.35156 5.31883 4.35156 4.02441ZM6.69531 7.86816C4.18573 7.86816 2.13676 9.84031 2.01367 12.3193H11.377C11.2539 9.84031 9.2049 7.86816 6.69531 7.86816Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgAccount;
