import React from "react";

const SvgUser = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			d="M20 0C8.975 0 0 8.975 0 20s8.975 20 20 20 20-8.975 20-20S31.025 0 20 0zm0 37.722c-4.632 0-8.838-1.807-11.997-4.723 1.929-4.738 6.576-8.094 11.997-8.094S30.068 28.261 31.997 33c-3.159 2.916-7.365 4.723-11.997 4.723zm-4.966-20.06A4.978 4.978 0 0 1 20 12.695a4.978 4.978 0 0 1 4.966 4.965A4.977 4.977 0 0 1 20 22.627a4.968 4.968 0 0 1-4.966-4.966zm9.446 5.649c1.67-1.337 2.764-3.357 2.764-5.65 0-3.994-3.25-7.243-7.244-7.243-3.994 0-7.244 3.25-7.244 7.243 0 2.293 1.094 4.313 2.764 5.65-4.054 1.245-7.38 4.16-9.203 7.927A17.583 17.583 0 0 1 2.293 20C2.278 10.22 10.22 2.278 20 2.278c9.765 0 17.722 7.942 17.722 17.722 0 4.267-1.519 8.17-4.024 11.238-1.838-3.767-5.163-6.682-9.218-7.927z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgUser;
