import React, { useState } from "react";
import { useQuery } from "@apollo/client";
import RouterLink from "next/link";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import clsx from "clsx";

import {
	useValueAtBreakpoint,
	Breakpoint,
	Link,
	LinkVariant,
	IconUser,
	IconExit,
	IconAccount,
	IconMailStroke,
	IconHelp,
} from "@jasperlabs/jux-next";

import { queries } from "api";
import { useLogOut } from "contexts/logOut";
import { CARUSO_HELP_CENTER } from "constants/external-links";
import {
	TenantPrivacyPolicyLink,
	TenantTermsAndConditionsLink,
	TenantSupportLink,
} from "tenants/components";

import { formatFullName } from "../../../utilities/stringFormat";

const LINK_STYLES =
	"bg-white py-2 px-5 transition-colors duration-200 hover:bg-neutral-50  flex w-full focus:no-underline";

const AccountDropdownMenu = () => {
	const [isOpen, setIsOpen] = useState(false);

	const { logOut } = useLogOut();

	const { data = {} } = useQuery(queries.ME);
	const { me = null } = data;
	const { firstName, lastName, email } = me || {};
	const fullName = formatFullName({ firstName, lastName });

	const menuButtonAriaLabel = isOpen
		? "Close account menu"
		: "Open account menu";

	const sideOffset = useValueAtBreakpoint({
		[Breakpoint.XS]: 15,
		[Breakpoint.LG]: 12,
	});

	return (
		<DropdownMenu.Root onOpenChange={(open) => setIsOpen(open)}>
			<DropdownMenu.Trigger asChild>
				<button
					type="button"
					className="text-link-nav hover:bg-button-inverted-nav-hover/20 flex h-[40px] items-center gap-2 rounded-md bg-transparent px-2 py-2 text-[30px] lg:h-[48px]"
					aria-label={menuButtonAriaLabel}
				>
					<IconUser />
				</button>
			</DropdownMenu.Trigger>
			<DropdownMenu.Portal>
				<DropdownMenu.Content
					aria-label="Account Menu"
					className="shadow-card-sm z-[9000] ml-[1vw] w-[98vw] divide-y divide-neutral-200 overflow-hidden rounded-md bg-white md:ml-0 md:w-[250px]"
					sideOffset={sideOffset}
					align="end"
					alignOffset={0}
				>
					<div className="flex py-3 px-5">
						{me && (
							<div className="w-full">
								<div
									className="text-subheading-sm truncate text-neutral-800"
									aria-label={fullName}
								>
									{fullName}
								</div>
								<div
									className="text-body-sm truncate text-neutral-500"
									aria-label={email}
								>
									{email}
								</div>
							</div>
						)}
					</div>
					<section>
						<DropdownMenu.DropdownMenuItem asChild>
							<Link
								icon={<IconAccount className="text-icon text-[18px]" />}
								variant={LinkVariant.Black}
								className={clsx("text-body-default", LINK_STYLES)}
								href="/preferences"
								as={RouterLink}
							>
								Account preferences
							</Link>
						</DropdownMenu.DropdownMenuItem>
						<DropdownMenu.DropdownMenuItem asChild>
							<TenantSupportLink
								icon={<IconMailStroke className="text-icon text-[18px]" />}
								variant={LinkVariant.Black}
								className={clsx("text-body-default", LINK_STYLES)}
							>
								Contact us
							</TenantSupportLink>
						</DropdownMenu.DropdownMenuItem>
						<DropdownMenu.DropdownMenuItem asChild>
							<Link
								icon={<IconHelp className="text-icon text-[20px]" />}
								variant={LinkVariant.Black}
								className={clsx("text-body-default", LINK_STYLES)}
								href={CARUSO_HELP_CENTER}
								target="_blank"
							>
								Help
							</Link>
						</DropdownMenu.DropdownMenuItem>
						<DropdownMenu.DropdownMenuItem asChild>
							<Link
								icon={<IconExit className="text-icon text-[18px]" />}
								variant={LinkVariant.Black}
								className={clsx("text-body-default", LINK_STYLES)}
								as="button"
								onClick={logOut}
							>
								Log out
							</Link>
						</DropdownMenu.DropdownMenuItem>
					</section>
					<section>
						<DropdownMenu.DropdownMenuItem asChild>
							<TenantTermsAndConditionsLink
								variant={LinkVariant.Black}
								className={clsx("text-body-xs", LINK_STYLES)}
							/>
						</DropdownMenu.DropdownMenuItem>
						<DropdownMenu.DropdownMenuItem asChild>
							<TenantPrivacyPolicyLink
								variant={LinkVariant.Black}
								className={clsx("text-body-xs", LINK_STYLES)}
							/>
						</DropdownMenu.DropdownMenuItem>
					</section>
				</DropdownMenu.Content>
			</DropdownMenu.Portal>
		</DropdownMenu.Root>
	);
};

export default AccountDropdownMenu;
