import React from "react";

const SvgMailStroke = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="0 0 17 12" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.44531 2.63093V9.18848L5.9763 5.65749L2.44531 2.63093ZM13.8837 10.25H3.50511L7.11855 6.63656L8.69512 7.98791L10.2709 6.63724L13.8837 10.25ZM14.9453 9.19032V2.63061L11.4132 5.65817L14.9453 9.19032ZM3.72244 1.75L8.69512 6.0123L13.6678 1.75H3.72244ZM2.32199 0.25C1.5816 0.25 0.945312 0.838971 0.945312 1.60938V10.3906C0.945312 11.161 1.5816 11.75 2.32199 11.75H15.0686C15.809 11.75 16.4453 11.161 16.4453 10.3906V1.60938C16.4453 0.838971 15.809 0.25 15.0686 0.25H2.32199Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgMailStroke;
