import React, { KeyboardEvent, useRef } from "react";
import { Disclosure } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import clsx from "clsx";

import { IconPlus } from "../icon";
import { createCtx } from "../../helpers";

interface AccordionContextInterface {
	buttonRefs: { current: HTMLElement[] };
	handleChange: (index: number) => void;
	closeOnOpen?: boolean;
}

const [useAccoridonCtx, AccordionProvider] =
	createCtx<AccordionContextInterface>();

export type AccordionProps = {
	children: React.ReactNode;
	/**
	 * When true, the accordion will close when another accordion is opened.
	 * @default true
	 */
	closeOnOpen?: boolean;
	className?: string;
};

export const Accordion = ({
	children,
	closeOnOpen,
	className,
}: AccordionProps) => {
	const buttonRefs = useRef<HTMLElement[]>([]);
	const openedRef = useRef<HTMLElement | null>(null);

	const handleChange = (index: number) => {
		const clickedButton = buttonRefs.current[index];
		if (clickedButton === openedRef.current) {
			openedRef.current = null;
			return;
		}
		if (openedRef.current?.getAttribute("data-value")) {
			openedRef.current?.click();
		}
		openedRef.current = clickedButton;
	};

	return (
		<AccordionProvider value={{ handleChange, buttonRefs, closeOnOpen }}>
			<div className={className}>{children}</div>
		</AccordionProvider>
	);
};

export type AccordionItemProps = {
	titleContent: React.FC;
	index: number;
	children: React.ReactNode;
	hasBorder?: boolean;
	defaultOpen?: boolean;
};

const AccordionItem = ({
	titleContent,
	children,
	index,
	hasBorder = true,
	defaultOpen = false,
}: AccordionItemProps) => {
	const { handleChange, buttonRefs, closeOnOpen } = useAccoridonCtx();

	const onChange = () => (closeOnOpen ? handleChange(index) : null);

	return (
		<Disclosure
			className={clsx(
				hasBorder && ["border-muted border-b py-3 last:border-none"],
			)}
			as="section"
			defaultOpen={defaultOpen}
		>
			{({ open }) => (
				<div>
					<Disclosure.Button
						as="button"
						data-value={open}
						onClick={() => onChange()}
						onKeyUp={(e: KeyboardEvent) =>
							e.key === "Enter" || (e.key === " " && onChange())
						}
						ref={(ref: HTMLButtonElement) => {
							if (ref) {
								buttonRefs.current[index] = ref;
							}
						}}
						className="w-full"
						data-testid={`toggle-container-${index}`}
					>
						{titleContent(open)}
					</Disclosure.Button>
					<AnimatePresence>
						{open && (
							<motion.div
								initial={{ height: 0 }}
								animate={{ height: "auto" }}
								exit={{ height: 0 }}
								className="overflow-hidden"
								data-testid={`collapse-container-${index}`}
								transition={{ duration: 0.3 }}
							>
								{children}
							</motion.div>
						)}
					</AnimatePresence>
				</div>
			)}
		</Disclosure>
	);
};

export type AccordionTitleProps = {
	toggleIcon?: React.ReactNode;
	children: React.ReactNode;
	className?: string;
};

const AccordionToggleIcon = () => (
	<div className="transform ui-open:rotate-45 rotate-0 text-xl text-inherit transition-all delay-200 duration-500">
		<IconPlus />
	</div>
);

const AccordionTitle = ({
	toggleIcon = <AccordionToggleIcon />,
	children,
	className,
}: AccordionTitleProps) => (
	<div
		className={clsx(
			"flex w-full cursor-pointer items-center justify-between font-bold",
			className,
		)}
	>
		{children}
		{toggleIcon}
	</div>
);

Accordion.ToggleIcon = AccordionToggleIcon;
Accordion.Title = AccordionTitle;
Accordion.Item = AccordionItem;
