/* eslint-disable import/no-unused-modules */

export const AU_CORPORATIONS_ACT =
	"https://www.legislation.gov.au/Details/C2020C00219";

export const NZ_FINANCIAL_CONDUCT_ACT_2008 =
	"http://www.legislation.govt.nz/act/public/2008/0091/latest/whole.html";
export const NZ_FINANCIAL_CONDUCT_ACT_2008_SECTION_9 =
	"http://www.legislation.govt.nz/act/public/2008/0091/latest/DLM1584644.html";
export const NZ_FINANCIAL_CONDUCT_ACT_2008_SECTION_77B =
	"http://www.legislation.govt.nz/act/public/2008/0091/latest/DLM3083740.html";
export const NZ_FINANCIAL_CONDUCT_ACT_2013 =
	"https://www.legislation.govt.nz/act/public/2013/0069/latest/whole.html";
export const NZ_FINANCIAL_CONDUCT_ACT_2013_SECTION_41 =
	"https://www.legislation.govt.nz/act/public/2013/0069/latest/DLM4092479.html?search=sw_096be8ed81a4f097_%22Eligible+Investor%22_25_se&p=1&sr=3";

export const CARUSO_HELP_CENTER =
	"https://help.getcaruso.com/en/collections/3853095-the-investor-portal";
export const CARUSO_MARKETING_SITE = "https://www.getcaruso.com";
