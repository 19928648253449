"use client";

import React, { createContext, useCallback, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { useApolloClient, useMutation } from "@apollo/client";

import { toast } from "components/utilities/toast";
import {
	mutations,
	useMutationOnError,
	useCaptureGraphQLError,
} from "../../api";

import { useSession } from "../session";

const logOutContext = createContext();

export const useLogOut = () => {
	const context = useContext(logOutContext);

	if (!context) {
		throw new Error("useLogOut should always be used within a LogOutProvider");
	}

	return context;
};

export const LogOutProvider = ({ children, ...rest }) => {
	const { removeSession, sessionToken } = useSession();
	const client = useApolloClient();
	const captureMutationError = useCaptureGraphQLError();
	const [mutate, { loading: isLoading, error }] = useMutationOnError(
		captureMutationError,
		useMutation(mutations.DESTROY_SESSION),
	);

	const logOut = useCallback(() => {
		mutate({
			variables: { token: sessionToken },
		});
		removeSession();
		client.clearStore();
		toast.success("You have successfully logged out.", {
			delay: 500,
		});
	}, [client, mutate, removeSession, sessionToken]);

	const value = useMemo(
		() => ({
			logOut,
			isLoggingOut: isLoading,
			logOutError: error,
		}),
		[error, isLoading, logOut],
	);

	return (
		<logOutContext.Provider value={value} {...rest}>
			{children}
		</logOutContext.Provider>
	);
};

LogOutProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
