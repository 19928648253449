import React from "react";

const SvgExit = (props: React.SVGProps<SVGSVGElement>) => (
	<svg width="1em" height="1em" viewBox="1 0 16 16" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.39246 3.19422V1.41602H15.0701V14.5836H6.39246V12.8054H13.2919V3.19422H6.39246ZM11.4958 8.89332H5.72557L7.29927 10.467L6.03675 11.7207L2.32031 8.00422L6.03675 4.27889L7.29927 5.54141L5.72557 7.11512H11.4958V8.89332Z"
			fill="currentColor"
		/>
	</svg>
);

export default SvgExit;
